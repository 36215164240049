import logo from './logo.svg';
import './App.css';
import { Component } from 'react';
import axios from 'axios';

class App extends Component {
  state = {
    apiResponse: '',
    errorMessage: ''
  }

  handleSubmit = event => {
    event.preventDefault(); // Prevent default behavior if necessary
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic QXBpX3Rva2VuOjIzYTgwMTg5MTllNjdjNjZiZTU3YThmNWNkMTI0NzQ1");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      "request_data": {
        "customerFullName": "Chandrakant Devani",
        "customerPhoneNumber": "8888888888",
        "emailId": "chandrakant.devani@digitas.com",
        "comment": "Please follow-up with the lead",
        "sku": "facebook01",
        "campaign": "test",
        "leadSource": 87
      }
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch("https://drupal.vanainfotech.com/parentune_data", requestOptions)
      .then((response) => response.text())
      .then((response) => {
        console.log('API Response:', response);
        this.setState({ apiResponse: response.data, errorMessage: '' });
      })
      .catch((error) => {
        console.error('Error:', error);
        this.setState({ errorMessage: 'An error occurred. Please try again.', apiResponse: '' });
      });
    // let data = JSON.stringify({
    //   "request_data": {
    //     "customerFullName": "Chandrakant Devani",
    //     "customerPhoneNumber": "8888888888",
    //     "emailId": "chandrakant.devani@digitas.com",
    //     "comment": "Please follow-up with the lead",
    //     "sku": "facebook01",
    //     "campaign": "test",
    //     "leadSource": 87
    //   }
    // });
    // const data = {
    //   "name": "Chandrakant",
    //   "address": "india",
    //   "age": 29
    // }
    // fetch('https://drupal.vanainfotech.com/parentune_data', {
    //   method: 'POST',
    //   headers: {
    //     'Authorization': 'Basic QXBpX3Rva2VuOjIzYTgwMTg5MTllNjdjNjZiZTU3YThmNWNkMTI0NzQ1'
    //   },
    //   body: data
    // })
    //   .then((response) => {
    //     console.log('API Response:', response);
    //     this.setState({ apiResponse: response.data, errorMessage: '' });
    //   })
    //   .catch((error) => {
    //     console.error('Error:', error);
    //     this.setState({ errorMessage: 'An error occurred. Please try again.', apiResponse: '' });
    //   });
    // axios.request({
    //   method: 'post',
    //   maxBodyLength: Infinity,
    //   url: 'https://drupal.vanainfotech.com/parentune_data',
    //   headers: {
    //     'Authorization': 'Basic QXBpX3Rva2VuOjIzYTgwMTg5MTllNjdjNjZiZTU3YThmNWNkMTI0NzQ1',
    //     // 'Content-Type': 'application/json',
    //     // 'Access-Control-Allow-Origin': '*'
    //   },
    //   data: data
    // })
    //   .then((response) => {
    //     console.log('API Response:', response);
    //     this.setState({ apiResponse: response.data, errorMessage: '' });
    //   })
    //   .catch((error) => {
    //     console.error('Error:', error);
    //     this.setState({ errorMessage: 'An error occurred. Please try again.', apiResponse: '' });
    //   });
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            onClick={this.handleSubmit}
            target="_blank"
            rel="noopener noreferrer"
          >
            Call Sample API
          </a>
          {this.state.apiResponse && (
            <div>
              <h3>API Response:</h3>
              <pre>{JSON.stringify(this.state.apiResponse, null, 2)}</pre>
            </div>
          )}
          {this.state.errorMessage && (
            <div style={{ color: 'red' }}>
              <h3>Error:</h3>
              <p>{this.state.errorMessage}</p>
            </div>
          )}
        </header>
      </div>
    );
  }
}

export default App;